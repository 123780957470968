import React from 'react'

function Footer() {
  return (
    <div style={{backgroundColor: '#121110de',width:'100%'}}>
        <div className='d-sm-flex py-3 justify-content-center align-items-center text-center text-white'>
            <p>&copy; 2024 Silva Cases Online,</p>
            <p>Crafted By <a style={{color:'#be01dd',fontWeight:"bold",textDecoration:"none"}} href='https://semicolonites.com/' target='_blank'>Semicolon ITES</a></p>
        </div>
    </div>
  )
}

export default Footer