import React, { useContext, useEffect, useState } from "react";
import {
  Modal,
  Container,
  Form,
  FormControl,
  Spinner,
  Image,
} from "react-bootstrap";
import { IoClose } from "react-icons/io5";
import { AllContext } from "../../../context/AllContext";
import Cookies from "js-cookie";
import { requestData2, zohoLeadFormSubmit } from "../../../utils/ApiRequests";
import toast from "react-hot-toast";

import { IPInfoContext } from 'ip-info-react';

function NewsLetterModal({ show, onHide }) {
  const [loading, setLoading] = useState(false);
  const { isDesktop } = useContext(AllContext);
  const [formData,setFormData] = useState({
    name:"",
    email:"",
  });

  const userInfo = useContext(IPInfoContext);



  const params = new URLSearchParams(window.location.search);

  const handleChange= (e)=>{
    const {name,value}= e.target;
    setFormData({
        ...formData,
        [name]: value
    })
  };

  const handleSubmit= async(e)=>{
    e.preventDefault();
    // console.log(formData,'formData');
    setLoading(true);
    try {

      const zohoRes = await zohoLeadFormSubmit(`zoho/add-lead`, 'POST', {
        name: formData?.name,
        email: formData?.email,
        phone: '',
        utm_source: params.get("utm_source") || "generic",
        utm_medium: params.get("utm_medium") || "",
        utm_campaign: params.get("utm_campaign") || "",
        utm_term: params.get("utm_term") || "",
        utm_content: params.get("utm_content") || "",
        referrer: document.referrer || "",  // Get the previous page URL
        page_url: window.location.origin + window.location.pathname, //,
        device: "web",
        form_name:"silvacases newsletter",
        country:userInfo.country_name
      }, {});

      const res= await requestData2('/auth/newsletter2','POST',formData,{});
      // console.log(res,'response');
      if(res?.success){
        toast.success(res?.message);
        onHide();
      }else{
        toast.error(res?.error)
      }
    } catch (error) {
      console.log(error?.message,'error');
      toast.error("Internal server error")
    }
    setLoading(false)
  }

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <div
        className="align-self-end rounded-5 m-2"
        style={{
          backgroundColor: '#d801fb85',
          cursor: 'pointer',
          padding: '4px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        onClick={onHide}
      >
        <IoClose color="#ffffffa6" size={20} />
      </div>
      <Container
        className={`p-${isDesktop ? 5 : 3} pt-2 pb-3 d-flex justify-content-center justify-content-lg-start pickcase-first overflow-y-scroll`}
      >
        <div style={{ width: "100%" }}>
          <div className="text-center mb-3">
          <h5>
            Craving Your Weekly <span style={{ color: "#d801fb" }}>Meditation</span> Fix?
          </h5>
          <b>Sign Up for Our Newsletter!</b>
          </div>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formBasicEmail" className="mb-2">
              <Form.Label className="m-1 fw-bold">Your Name</Form.Label>
              <FormControl
                type="text"
                name="name"
                placeholder="Type here..."
                style={{ backgroundColor: "#f8f5fc", height: "50px" }}
                value={formData?.name}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="formBasicEmail" className="mb-2">
              <Form.Label className="m-1 fw-bold">Email Address</Form.Label>
              <FormControl
                type="text"
                name="email"
                placeholder="Type here..."
                style={{ backgroundColor: "#f8f5fc", height: "50px" }}
                value={formData?.email}
                onChange={handleChange}
              />
            </Form.Group>
            <div className="w-50 m-auto text-center">
              <button className="benifits-card px-4 py-2 border-0 rounded mt-4 fw-bold">
                {loading ? (
                  <Spinner animation="border" variant="light" size="sm" />
                ) : (
                  "Subscribe Now"
                )}
              </button>
            </div>
          </Form>
        </div>
      </Container>
      <div>
        <Image src="/assets/img/banner-img-webp.webp" className="w-100 rounded-4" />
      </div>
    </Modal>
  );
}

export default NewsLetterModal;
