import React, { useContext, useEffect, useState } from "react";
import { Modal, Container, Accordion, Spinner, Row, Col } from "react-bootstrap";
import { IoClose } from "react-icons/io5";
import toast from "react-hot-toast";
import moment from "moment";
import { AllContext } from "../../../context/AllContext";
import { requestData2 } from "../../../utils/ApiRequests";
import Swal from "sweetalert2";

function ViewCase(props) {
  const [loading, setLoading] = useState({ state: false, name: "" });
  const [myCases, setMyCases] = useState([]);

  const { isDesktop, isBigScreen } = useContext(AllContext);

  //   console.log(myCases,'case...');

  const getCases = async () => {

    const headers = {
      Authorization: `Bearer ${JSON.parse(
        localStorage.getItem("silvacasetoken")
      )}`,
    };

    setLoading({ state: true, name: "getcases" });

    try {
      const res = await requestData2(
        "/private/viewmycases",
        "GET",
        {},
        headers
      );
      console.log(res, 'myres')
      if (res.success) {
        // toast.success("Data successfully retrieved");
        setMyCases(res.data);
      } else {
        toast.error(res.error);
      }
    } catch (error) {
      console.log(error, "error");
    }

    setLoading({ state: false, name: "" });
  };


  const handleDeleteCase = async (id) => {

    setLoading({ state: true, name: 'deletecase' });

    try {
      const res = await requestData2("/private/deletemycase", "DELETE", {
        caseId: id
      }, {
        Authorization: `Bearer ${JSON.parse(
          localStorage.getItem("silvacasetoken")
        )}`,
      });
      if (res?.success) {
        await getCases();

        Swal.fire({
          title: "Success",
          text: res?.message || "Case deleted successfully.",
          icon: "success"
        });
      } else {
        Swal.fire({
          title: "Error",
          text: res?.message || "Case delete Failed. Try again later",
          icon: "error"
        });
      }
    } catch (error) {

      console.log(error, 'error deleting case');

      Swal.fire({
        title: "Error",
        text: "Something wrong, please try again later",
        icon: "error"
      });
    }

    setLoading({ state: false, name: "" })
  }

  useEffect(() => {
    getCases();
  }, [props])

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      className="viewcase-modal"
    >
      <div
        className="align-self-end rounded-5 m-2"
        style={{ backgroundColor: '#d801fb85', cursor: 'pointer', padding: '4px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        onClick={() => props.onHide()}
      >
        <IoClose color="#ffffffa6" size={20} />
      </div>
      <Container
        className={`p-${isDesktop ? 5 : 3
          } pt-2 d-flex justify-content-center justify-content-lg-start overflow-y-scroll`}
      >
        {loading.state && loading.name === "getcases" ? (
          <div className="d-flex justify-content-center justify-content-lg-start">
            <Spinner />
          </div>
        ) : (
          <div style={{ width: isDesktop ? "60%" : "100%", textAlign: 'center', justifyContent: 'center' }}>
            <h3>
              My <span style={{ color: "#d801fb" }}>Cases</span>
            </h3>
            {myCases?.length > 0 ? (
              <div className="mt-5">
                <h3
                  className={`d-${isDesktop ? "flex gap-2" : ""
                    } align-items-center`}
                >
                  Total No of Case
                  <div className="d-flex gap-1 align-items-center">
                    <span>Retrived</span>
                    <div
                      style={{
                        backgroundColor: "#ffd401",
                        width: "30px",
                        height: "30px",
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "18px",
                      }}
                    >
                      {myCases?.length}
                    </div>
                  </div>
                </h3>
              </div>) :
              (
                <div>
                  <h6 className="my-3"> No <span style={{ color: "#d801fb" }}>Case(s)</span> Found</h6> </div>
              )
            }
            <div className="mt-3">
              <Accordion defaultActiveKey={0} className="pb-4">
                {myCases?.map((el, i) => (
                  <Accordion.Item eventKey={i} key={i}>
                    <Accordion.Header>
                      {isDesktop || isBigScreen ? (
                        <Row className="w-100">
                          <Col sm={6} md={6} lg={3}>
                            <div className="py-1 border-end border-dark">
                              <p>Patient Name</p>
                              <b>{el?.name.split(" ")[0]}</b>
                            </div>
                          </Col>
                          <Col sm={6} md={6} lg={3}>
                            <div className="py-1 border-end border-dark">
                              <p>Gender</p>
                              <b>{el?.sex}</b>
                            </div>
                          </Col>
                          <Col sm={6} md={6} lg={3}>
                            <div className="py-1 border-end border-dark">
                              <p>Country</p>
                              <b>{el?.country}</b>
                            </div>
                          </Col>
                          <Col sm={6} md={6} lg={3}>
                            <div className="py-1">
                              <p>Posted On:</p>
                              <b>{moment(el?.createdAt).format("YYYY-MM-DD")}</b>
                            </div>
                          </Col>
                        </Row>
                      ) : (
                        <div
                          className="d-flex overflow-scroll"
                          style={{ whiteSpace: "nowrap" }}
                        >
                          <div className="p-3 py-1 border-end border-dark">
                            <p>Patient Name</p>
                            <b>{el?.name}</b>
                          </div>
                          <div className="p-3 py-1 border-end border-dark">
                            <p>Sex</p>
                            <b>{el?.sex}</b>
                          </div>
                          <div className="p-3 py-1 border-end border-dark">
                            <p>Country</p>
                            <b>{el?.country}</b>
                          </div>
                          <div className="p-2">
                            <p>Posted On:</p>
                            <b>{moment(el?.createdAt).format("YYYY-MM-DD")}</b>
                          </div>
                        </div>
                      )}
                    </Accordion.Header>
                    <Accordion.Body className="p-0 m-0">
                      {/* <div
                      className="d-flex"
                      style={{ whiteSpace: "nowrap", overflowX: "scroll" }}
                    >
                      <div className="p-3 py-1  border-dark text-center">
                        <p>Age</p>
                        <b>{el?.age}</b>
                      </div>
                      <div className="p-3 py-1 border-start border-dark text-center">
                        <p>City</p>
                        <b>{el?.city}</b>
                      </div>
                      <div className="p-3 py-1 border-start border-dark text-center">
                        <p>State</p>
                        <b>{el?.state}</b>
                      </div>
                    </div> */}
                      <Row className="border-top border-bottom border-dark w-100 m-auto py-2">
                        <Col
                          sm={4}
                          md={4}
                          lg={4}
                          className="p-3 py-1 border-dark text-center"
                        >
                          <p>Age</p>
                          <b>{el?.age}</b>
                        </Col>
                        <Col
                          sm={4}
                          md={4}
                          lg={4}
                          className="p-3 py-1 border-start border-dark text-center"
                        >
                          <p>City</p>
                          <b>{el?.city || "Not Provided"}</b>
                        </Col>
                        <Col
                          sm={4}
                          md={4}
                          lg={4}
                          className="p-3 py-1 border-start border-dark text-center"
                        >
                          <p>State</p>
                          <b>{el?.state || "Not Provided"}</b>
                        </Col>
                      </Row>
                      <Container>
                        <div className="border-bottom border-dark w-100 m-auto py-2">
                          <p>Medical Diagnosis (if known)</p>
                          <b>{el?.medicalDiagnosis}</b>
                        </div>
                        <div className="border-bottom border-dark w-100 m-auto py-2">
                          <p>
                            {" "}
                            Symptoms as perceived by the subject; describe all
                            health problems and specific location,which side
                            (left,right) etc. Use lay terminology only; no medical
                            terminology in this section
                          </p>
                          <b>{el?.symptoms}</b>
                        </div>
                        <div className="border-bottom border-dark w-100 m-auto py-2">
                          <p>
                            Past Operations/Accidents (with approximate dates if
                            known, month/year is fine)
                          </p>
                          <b>{el?.pastOperations}</b>
                        </div>
                        <div className="border-bottom border-dark w-100 m-auto py-2">
                          <p>Dominant Personality Characteristics</p>
                          <b>{el?.personalityCharacteristics}</b>
                        </div>
                        <div className="border-bottom border-dark w-100 m-auto py-2">
                          <p>
                            {" "}
                            General Description: height (ft./in.), weight (lbs.),
                            eye color, hair color, complexion, glasses?, smoker?,
                            occupation
                          </p>
                          <b>{el?.desc}</b>
                        </div>
                        <div className="border-bottom border-dark w-100 m-auto py-2">
                          <p>Any other Relevant Information not listed above</p>
                          <b>{el?.otherDesc}</b>
                        </div>
                        <button className="px-4 py-2 rounded-3 bg-warning border border-none my-4" onClick={()=>handleDeleteCase(el?._id)}>{loading.state && loading.name === "deletecase" ? <Spinner /> : "Delete Case"}</button>
                      </Container>
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </div>
          </div>
        )}
      </Container>
    </Modal>
  );
}

export default ViewCase;
