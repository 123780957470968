import React, { useContext, useState } from "react";
import {
  Col,
  Form,
  FormControl,
  InputGroup,
  Row,
  Spinner,
} from "react-bootstrap";
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";
import { allcodes } from "../../../utils/allcodes";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";
import { requestData2, zohoLeadFormSubmit } from "../../../utils/ApiRequests";
import VerifyEmailModal from "./VerifyEmailModal";
import { IPInfoContext } from 'ip-info-react';

function RegisterForm() {
  const [showPassword, setShowPassword] = useState({ status: false, name: "" });
  const [registerValues, setRegisterValues] = useState({
    name: "",
    email: "",
    password: "",
    confirmpass: "",
    countryCode: "",
    phone: "",
  });

  const userInfo = useContext(IPInfoContext);

  const params = new URLSearchParams(window.location.search);

  const [termsAccepted, setTermsAccepted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showVerify, setShowVerify] = useState(false);
  const [acceptNewLetter, setAcceptNewLetter] = useState(true);

  const handleTogglePassword = (name) => {
    if (showPassword.name === "") {
      setShowPassword({ status: true, name: name });
    } else {
      setShowPassword({ status: false, name: "" });
    }
  };

  const handleCountryCode = (e) => {
    const val = e.target.value;

    if (val !== "Select a country code") {
      setRegisterValues({
        ...registerValues,
        countryCode: e.target.value,
      });
    } else {
      setRegisterValues({
        ...registerValues,
        countryCode: "",
      });
    }
  };

  const handleReset = (e) => {
    e.preventDefault();
    setRegisterValues({
      name: "",
      email: "",
      password: "",
      confirmpass: "",
      countryCode: "",
      phone: "",
    });
    setTermsAccepted(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Name validation
    if (!registerValues.name) {
      toast.error("Please enter your full name");
      return;
    }

    // Email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(registerValues.email)) {
      toast.error("Please enter a valid email address");
      return;
    }

    // Phone validation
    if (registerValues.phone.length < 6) {
      toast.error("Please enter a valid phone number");
      return;
    }

    // Country code validation
    if (!registerValues.countryCode) {
      toast.error("Please select a country code");
      return;
    }

    // Password validation
    if (registerValues.password.length < 3) {
      toast.error("Password must be at least 3 characters long");
      return;
    }

    // Confirm Password validation
    if (registerValues.confirmpass !== registerValues.password) {
      toast.error("Password and confirm password must be same");
      return;
    }

    if (!termsAccepted) {
      toast.error("Please accept the terms and conditions to proceed");
      return;
    }
    setLoading(true);
    try {

      const zohoRes = await zohoLeadFormSubmit(`zoho/add-lead`, 'POST', {
        name: registerValues.name,
        email: registerValues.email,
        phone: registerValues.phone,
        utm_source: params.get("utm_source") || "generic",
        utm_medium: params.get("utm_medium") || "",
        utm_campaign: params.get("utm_campaign") || "",
        utm_term: params.get("utm_term") || "",
        utm_content: params.get("utm_content") || "",
        referrer: document.referrer || "",  // Get the previous page URL
        page_url: window.location.origin + window.location.pathname, //,
        device: "web",
        form_name: "silvacases register form",
        country: userInfo?.country_name
      }, {});

      const res = await requestData2('/auth/register', 'POST', registerValues);

      if (acceptNewLetter) {
        const newsLetterRes = await requestData2('/auth/newsletter', 'POST', { email: registerValues?.email });
        if (newsLetterRes?.success) {
          toast.success(newsLetterRes?.message)
        } else {
          toast.error(newsLetterRes?.error)
        }
      }
      // console.log(res,'response register');
      if (res.success) {
        toast.success(res?.message)
        setShowVerify(true);
      } else {
        toast.error(res?.error === "Duplicate Field value entered" ? "Email or mobile number you entered, already registered" : res?.error)
      }
    } catch (error) {
      // console.log(error,'register err')
      toast.error(error?.error)
    }
    setLoading(false);

  };

  return (
    <div className="col-lg-6 mb-5 mb-lg-0 position-relative">
      <div
        id="radius-shape-1"
        className="position-absolute rounded-circle shadow-5-strong"
      ></div>
      <div id="radius-shape-2" className="position-absolute shadow-5-strong"></div>

      <div className="card bg-glass">
        <div className="p-5 pb-0 d-flex justify-content-center align-items-center">
          <img src="/assets/img/silvamethod-logo.png" alt="SilvaLogo" />
        </div>
        <div className="card-body px-4 py-5 px-md-5">
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col sm={12} lg={6}>
                <Form.Group controlId="RegisterName" className="mb-3 text-start">
                  <Form.Label className="m-1">Full Name</Form.Label>
                  <FormControl
                    type="text"
                    placeholder="Enter name"
                    value={registerValues.name}
                    onChange={(e) =>
                      setRegisterValues({
                        ...registerValues,
                        name: e.target.value,
                      })
                    }
                    autoComplete={false}
                    style={{ backgroundColor: "#f8f5fc", height: "50px" }}
                  />
                </Form.Group>
              </Col>
              <Col sm={12} lg={6}>
                <Form.Group controlId="RegisterEmail" className="mb-3 text-start">
                  <Form.Label className="m-1">Email address</Form.Label>
                  <FormControl
                    type="email"
                    placeholder="Enter email"
                    value={registerValues.email}
                    onChange={(e) =>
                      setRegisterValues({
                        ...registerValues,
                        email: e.target.value,
                      })
                    }
                    autoComplete={false}
                    style={{ backgroundColor: "#f8f5fc", height: "50px" }}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={12} lg={6}>
                <Form.Group controlId="RegisterPhone" className="mb-3 text-start">
                  <Form.Label className="m-1">Phone Number</Form.Label>
                  <FormControl
                    type="tel"
                    placeholder="Enter Phone Number"
                    value={registerValues.phone}
                    onChange={(e) =>
                      setRegisterValues({
                        ...registerValues,
                        phone: e.target.value,
                      })
                    }
                    autoComplete={false}
                    style={{ backgroundColor: "#f8f5fc", height: "50px" }}
                  />
                </Form.Group>
              </Col>
              <Col sm={12} lg={6}>
                <Form.Group controlId="RegisterCountryCode" className="mb-3 text-start">
                  <Form.Label className="m-1">Select Country Code</Form.Label>
                  <Form.Select
                    value={registerValues.countryCode}
                    style={{
                      borderRight: "none",
                      backgroundColor: "#f8f5fc",
                      height: "50px",
                    }}
                    onChange={handleCountryCode}
                  >
                    <option value="" disabled hidden>
                      Select Country Code
                    </option>
                    {allcodes.map((code, index) => (
                      <option
                        key={index + 1}
                        value={code.dial_code}
                        style={{ width: "auto" }}
                      >
                        {code.name}
                        {`(${code.dial_code})`}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={12} lg={6}>
                <Form.Group controlId="RegisterPassword" className="mb-3 text-start">
                  <Form.Label className="m-1">Password</Form.Label>
                  <InputGroup>
                    <FormControl
                      type={
                        showPassword.status && showPassword.name === "password"
                          ? "text"
                          : "password"
                      }
                      placeholder="Password"
                      value={registerValues.password}
                      onChange={(e) =>
                        setRegisterValues({
                          ...registerValues,
                          password: e.target.value,
                        })
                      }
                      autoComplete={false}
                      style={{
                        borderRight: "none",
                        backgroundColor: "#f8f5fc",
                        height: "50px",
                      }}
                    />
                    <InputGroup.Text
                      style={{ cursor: "pointer", backgroundColor: "#f8f5fc" }}
                      onClick={() => handleTogglePassword("password")}
                    >
                      {showPassword.status &&
                        showPassword.name === "password" ? (
                        <AiOutlineEyeInvisible size={20} />
                      ) : (
                        <AiOutlineEye size={20} />
                      )}
                    </InputGroup.Text>
                  </InputGroup>
                </Form.Group>
              </Col>
              <Col sm={12} lg={6}>
                <Form.Group
                  controlId="RegisterConfirmPassword"
                  className="mb-3 text-start"
                >
                  <Form.Label className="m-1">Confirm Password</Form.Label>
                  <InputGroup>
                    <FormControl
                      type={
                        showPassword.status &&
                          showPassword.name === "confirmpassword"
                          ? "text"
                          : "password"
                      }
                      placeholder="Confirm Password"
                      value={registerValues.confirmpass}
                      onChange={(e) =>
                        setRegisterValues({
                          ...registerValues,
                          confirmpass: e.target.value,
                        })
                      }
                      autoComplete={false}
                      style={{
                        borderRight: "none",
                        backgroundColor: "#f8f5fc",
                        height: "50px",
                      }}
                    />
                    <InputGroup.Text
                      style={{ cursor: "pointer", backgroundColor: "#f8f5fc" }}
                      onClick={() => handleTogglePassword("confirmpassword")}
                    >
                      {showPassword.status &&
                        showPassword.name === "confirmpassword" ? (
                        <AiOutlineEyeInvisible size={20} />
                      ) : (
                        <AiOutlineEye size={20} />
                      )}
                    </InputGroup.Text>
                  </InputGroup>
                </Form.Group>
              </Col>
            </Row>
            <Form.Check
              type="checkbox"
              label={
                <p style={{ fontWeight: "bold", color: "black" }}>
                  I have read and agree to the{" "}
                  <Link to={"/terms"} style={{ textDecoration: "none" }}>
                    <span style={{ color: "#7b14d4" }}>terms</span>
                  </Link>
                </p>
              }
              checked={termsAccepted}
              onChange={() => setTermsAccepted(!termsAccepted)}
            />
            <Form.Check
              type="checkbox"
              label={
                <p style={{ fontWeight: "bold", color: "black" }}>
                  Sign me up for The Silva Method's email newsletter with powerful ideas on improving your life.
                </p>
              }
              checked={acceptNewLetter}
              onChange={() => setAcceptNewLetter(!acceptNewLetter)}
            />

            <button className="benifits-card px-5 py-2 border-0 rounded mt-3 me-3">
              {loading ? (
                <Spinner animation="border" variant="light" size="sm" />
              ) : (
                "Register"
              )}
            </button>
            <button className="benifits-card px-5 py-2 border-0 rounded mt-3" onClick={handleReset}>
              Clear
            </button>
          </Form>
        </div>
      </div>
      <VerifyEmailModal show={showVerify} onHide={() => setShowVerify(false)} registerValues={registerValues} setRegisterValues={setRegisterValues} />
    </div>
  );
}

export default RegisterForm;
